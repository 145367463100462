import React from 'react'
import {Link}  from 'react-router-dom'
import './style.css'

import Dm1 from './images/demo/1.jpg'
import Dm2 from './images/demo/2.jpg'
import Dm3 from './images/demo/3.jpg'
import Dm4 from './images/demo/4.jpg'
import Dm5 from './images/demo/5.jpg'
import Dm6 from './images/demo/6.jpg'
import Dm7 from './images/demo/7.jpg'
import Dm8 from './images/demo/8.jpg'
import Dm9 from './images/demo/9.jpg'
import Dm10 from './images/demo/10.jpg'

const DemoHome = () => {
    return(
        <div id="demo" className="demo-area section-padding">
            <div className="container">
                <div className="col-12">
                    <div className="section-title demosection-title text-center">
                        <h2>10 Stunning Demos</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/"><img src={Dm1} alt="" /></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/" target="_blank">Personal Portfolioo</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/home2"><img src={Dm2} alt="" /></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/home2" target="_blank">Digital Agency </Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/home3"><img src={Dm3} alt="" /></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/home3" target="_blank">Design Agency</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/home4"><img src={Dm4} alt="" /></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/home4" target="_blank">Designer</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/home5"><img src={Dm5} alt="" /></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/home5" target="_blank">Programmer</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/home6"><img src={Dm6} alt="" /></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/home6" target="_blank">Bloger</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/home7"><img src={Dm7} alt="" /></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/home7" target="_blank">Architect</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/home8"><img src={Dm8} alt="" /></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/home8" target="_blank">Doctor</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/home9"><img src={Dm9} alt="" /></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/home9" target="_blank">Lawyer</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/home10"><img src={Dm10} alt="" /></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/home10" target="_blank">3D Designer</Link></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DemoHome;