import React from 'react'
import {Link}  from 'react-router-dom'
import './style.css'

import Dm1 from './images/demo/11.jpg'
import Dm2 from './images/demo/12.jpg'
import Dm3 from './images/demo/13.jpg'
import Dm4 from './images/demo/14.jpg'
import Dm5 from './images/demo/15.jpg'
import Dm6 from './images/demo/16.jpg'
import Dm7 from './images/demo/17.jpg'
import Dm8 from './images/demo/18.jpg'
import Dm9 from './images/demo/19.jpg'
import Dm10 from './images/demo/20.jpg'
import Dm11 from './images/demo/21.jpg'
import Dm12 from './images/demo/22.jpg'
import Dm13 from './images/demo/23.jpg'
import Dm14 from './images/demo/24.jpg'

const DemoSinglepage = () => {
    return(
        <div id="page" className="demo-area section-padding">
            <div className="container">
                <div className="col-12">
                    <div className="section-title demosection-title text-center">
                        <h2>14 Single Pages</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/about"><img src={Dm1} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/about" target="_blank">About Page</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/contact"><img src={Dm2} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/contact" target="_blank">Contact Page</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/protfolioGrid2"><img src={Dm3} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/protfolioGrid2" target="_blank">Portfolio Grid 2 Column</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/protfolioGrid"><img src={Dm4} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/protfolioGrid" target="_blank">Portfolio Grid 3 Column</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/protfolioMashonary"><img src={Dm5} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/protfolioMashonary" target="_blank">Portfolio Masonry</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/protfolioList"><img src={Dm6} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/protfolioList" target="_blank">Portfolio Listing</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/protfolioCro"><img src={Dm7} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/protfolioCro" target="_blank">Portfolio Slider</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/projectDetails"><img src={Dm8} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/projectDetails" target="_blank">Project Details</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/serviceDetails"><img src={Dm9} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/serviceDetails" target="_blank">Service Details</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/blog"><img src={Dm10} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/blog" target="_blank">Blog Page</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/blogfullwidth"><img src={Dm11} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/blogfullwidth" target="_blank">Blog FullWidth</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/blog-details"><img src={Dm13} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/blog-details" target="_blank">Blog Single</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/blog-details-fullwidth"><img src={Dm14} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/blog-details-fullwidth" target="_blank">Blog Single Fullwidth</Link></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 custom-grid">
                        <div className="single-demo text-center">
                            <div className="demo-img">
                                <Link target="_blank" to="/404"><img src={Dm12} alt=""/></Link>
                            </div>
                            <div className="demo-content">
                                <h3><Link to="/404" target="_blank">404 Page</Link></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DemoSinglepage;