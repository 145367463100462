import React from 'react'
import './style.css'

const AbService = () => {
    return(
        <div className="wpo-about-item">
            <div className="wpo-service-item">
                <div className="row">
                <div className="col-lg-6">
                    <div className="wpo-service-wrap">
                        <div className="wpo-services-icon-wrap">
                            <div className="wpo-service-icon">
                                <i className="fi flaticon-idea"></i>
                            </div>
                        </div>
                        <div className="wpo-service-text">
                            <h2>Product  Design</h2>
                            <p>consectetur adipiscing elit, eiusmod tempor incididunt labore.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="wpo-service-wrap">
                        <div className="wpo-services-icon-wrap">
                            <div className="wpo-service-icon-2">
                                <i className="fi flaticon-files-and-folders"></i>
                            </div>
                        </div>
                        <div className="wpo-service-text">
                            <h2>Web  Design</h2>
                            <p>consectetur adipiscing elit, eiusmod tempor incididunt labore.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="wpo-service-wrap">
                        <div className="wpo-services-icon-wrap">
                            <div className="wpo-service-icon-3">
                                <i className="fi flaticon-artist"></i>
                            </div>
                        </div>
                        <div className="wpo-service-text">
                            <h2>Visual Design</h2>
                            <p>consectetur adipiscing elit, eiusmod tempor incididunt labore.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="wpo-service-wrap">
                        <div className="wpo-services-icon-wrap">
                            <div className="wpo-service-icon-4">
                                <i className="fi flaticon-man"></i>
                            </div>
                        </div>
                        <div className="wpo-service-text">
                            <h2>Business Strategy</h2>
                            <p>consectetur adipiscing elit, eiusmod tempor incididunt labore.</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AbService;